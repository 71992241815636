<template>
  <article
    class="p-15 rounded border hover:border-dark-60 transition-all duration-250 ease-out cursor-pointer"
    :class="{
      'border-primary shadow-md': selected,
      'border-dark-20 shadow-none': !selected
    }"
  >
    <header class="flex justify-between mb-10">
      <div class="flex items-center">
        <img
          :src="`https://res.cloudinary.com/dili9mwxz/image/upload/airlines/${booking.segments[0].marketing_carrier}.png`"
          class="w-35 h-35 mr-15"
          alt="marketing carrier"
        />
        <p class="text-14 flex flex-col flex-shrink-0 items-start">
          <small class="text-12 opacity-60">
            Compagnie
          </small>
          {{ booking.segments[0].marketing_carrier_name }}
        </p>
      </div>
      
    </header>
    <main>
      <SegmentMini
        v-for="segment in booking.segments"
        :key="segment.id"
        :segment="segment"
        class="mt-5"
      />
    </main>
  </article>
</template>

<script>
import SegmentMini from '@/components/core/SegmentMini.vue'

export default {
  name: 'BookingCard',
  props: ['booking', 'selected'],
  components: { SegmentMini }
}
</script>
