import { createApp } from 'vue'
import router from '@/router'
import store from '@/store'
import date from '@/plugins/date-fns.js'
import '@/styles/main.css'
import App from './App.vue'

// Init app
const APP = createApp(App)

// Set config
date(APP)
APP.use(router)
APP.use(store)
APP.mount('#app')
