<template>
  <div class="py-50" v-if="order">
    <div class="grid md:grid-cols-3 gap-15 mb-30">
      <BookingCard
        v-for="booking in bookings"
        :key="booking.id"
        :booking="booking"
        :selected="booking.id.toString() === bookingId"
        @click="$router.push(`/cancellation/${order.id}/option/${booking.id}`)"
      />
    </div>
    <hr class="mb-30 border-dark-20" />
    <p class="text-18 opacity-60 leading-none">
      Vol {{order.origin_extra.city_data.name}} - {{order.destination_extra.city_data.name}} annulé
    </p>

    <!-- <pre>{{ selectedOption }}</pre> -->

    <p class="mb-2 text-20 font-medium mb-20">
      Sélectionnez l’option de votre choix :
    </p>

    <div class="grid md:grid-cols-2 gap-15">
      <AirlineOption
        v-for="option in options"
        :key="option.type"
        :option="option"
        @click="selectedOption = {optionType: option.type, ...option}"
        :checked="selectedOption?.optionType === option.type"
        :class="selectedOption?.optionType !== undefined && selectedOption?.optionType !== option.type ? 'opacity-50' : ''"
        :isRoundTrip='isRoundTrip'
        v-model="optionValue"
      />

      <AirlineOption
        v-for="option in unavailableOptions"
        :key="option.type"
        :option="option"
        class="opacity-50"
      />
    </div>

    <Information class="mt-60 mb-30">
      <p class="text-14">
        Si une option apparait comme “non disponible” c’est parce qu’elle n’est malheureusement pas proposée par la compagnie aérienne. 
      </p>
    </Information>

    <footer class="flex flex-row bottom-0 justify-end my-20 py-20 bg-white border-t border-dark-20">
      <router-link 
        class="border border-dark-10 text-dark-60 rounded-sm text-black p-10 w-1/2 text-16 text-center mr-5"
        :to="previousStep"
      >
        Retour
      </router-link>

      <component 
        class="border-none rounded-sm bg-primary text-white p-10 w-1/2 text-16 text-center ml-5"
        :class="selectedOption?.optionType === undefined ? 'opacity-50 disabled' : ''"
        :to="nextStep"
        :is="selectedOption?.optionType === undefined ? 'span' : 'router-link'"
      >
        Confirmer
      </component>
    </footer>
  </div>
</template>

<script>
import BookingCard from '@/components/core/BookingCard.vue'
import AirlineOption from "@/components/cancellation/AirlineOption.vue";
import Information from '@/components/Information.vue'

export default {
  name: 'BookingCancellation',
  components: {
    AirlineOption,
    BookingCard,
    Information
  },
  data() {
    return {
      optionValue: {}
    }
  },
  computed: {
    bookings() {
      return this.$store.getters['bookings']
    },
    bookingId() {
      return this.$route.params.bookingId;
    },
    currentBooking() {
      return this.$store.getters['bookings'].find(i => i.id.toString() === this.bookingId)
    },
    isRoundTrip() {
      let statuses = this.$store.getters['bookings'].find(i => i.id.toString() === this.bookingId).segments.map((i) => i.is_inbound);

      return (statuses.indexOf(true) !== -1 && statuses.indexOf(false) !== -1)
    },
    order() {
      return this.$store.state.order
    },
    options() {
      return this.$store.getters.getBookingOptionsById(this.bookingId).filter((i) => i.available === true)
    },
    unavailableOptions() {
      return this.$store.getters.getBookingOptionsById(this.bookingId).filter(
        (i) => i.available !== true && i.type !== 'exception'
      )
    },
    previousStep() {
      const currentIndex = this.bookings.map(b => b.id).indexOf(
        parseInt(this.bookingId)
      );

      if(currentIndex === 0) {
        return `/cancellation/${this.order.id}/home`
      }

      return `/cancellation/${this.order.id}/option/${this.bookings[currentIndex-1].id}`;
    },
    nextStep() {
      const currentIndex = this.bookings.map(b => b.id).indexOf(
        parseInt(this.bookingId)
      );

      if((currentIndex+1) === this.bookings.length) {
        return `/cancellation/${this.order.id}/recap`
      }

      return `/cancellation/${this.order.id}/option/${this.bookings[currentIndex+1].id}`;
    },
    selectedOption: {
      get: function() {
        return this.$store.state.userOptions[this.bookingId]
      },
      set: function(newValue) {
        this.$store.commit('setUserOption', {
          bookingId: this.bookingId,
          ...newValue
        })
      }
    }
  },
  watch: {
    optionValue: {
      deep: true,
      handler() {
        this.$store.commit('setUserOptionValue', {
          bookingId: this.$route.params.bookingId,
          optionValue: this.optionValue
        })
      }
    }
  },
  mounted() {

  },
  beforeUnmount() {
    
  }
}
</script>
