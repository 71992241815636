<template>
  <div class="py-50" v-if="order">
    <p class="font-medium text-24 text-dark">
      Bienvenue {{ pax.given_name }} <br/>
      sur votre Espace Annulation,
    </p>
    <p class="mb-70 mt-10">
      Un de vos vols a été annulé, vous avez plusieurs options ! Consultez les options proposées par les compagnies aériennes pour l’ensemble de vos vols, et choisissez celles que vous préférez. Ensuite, on s’occupe de tout.
    </p>

    <div v-for="booking in bookings" :key="booking.id">
      <div class="grid grid-cols-2 gap-30 mb-30">
        <segment v-for="segment in booking.segments" :key="segment.id" :segment="segment" />
      </div>
    </div>

    <Information class="mt-60 mb-30">
      <p class="text-14 mb-10">
        <span class="text-danger">Vol(s) annulé(s) :</span> vous allez devoir choisir une option (et une seulement), selon la politique de la compagnie aérienne. Nous gérerons tout pour vous ensuite.
      </p>
      <p class="text-14">
        <span class="text-success">Vol(s) maintenu(s) :</span> vous n’avez rien à faire ! 
      </p>

      <p class="text-14 mt-10">
        Découvrez les options disponibles sur tous vos vols, annulés ou maintenus, pour prendre les meilleures décisions selon vos besoins et les politiques des compagnies aériennes.
      </p>

    </Information>

    <footer class="flex justify-end my-10 py-20 bg-white border-t border-dark-20 z-40">
      <router-link 
        class="border-none rounded-sm bg-primary text-white p-10 w-full text-16 text-center"
        :to="`/cancellation/${$store.state.orderId}/option/${bookings[0].id}`"
      >
        Découvrir vos options d’annulation
      </router-link>
    </footer>
  </div>
</template>

<script>
import Information from '@/components/Information.vue'
import Segment from '@/components/core/Segment.vue'

export default {
  name: 'HomeCancellation',
  components: { Information, Segment },
  computed: {
    order() {
      return this.$store.state.order
    },
    bookings() {
      return this.$store.getters['bookings']
    },
    pax() {
      return this.$store.state.order.passengers[0]
    }
  },
  mounted() {

  }
}
</script>
