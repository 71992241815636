import { createStore } from 'vuex'
import axios from 'axios'

const API_URL = `https://ice-cream-support.herokuapp.com`;
const ULYSSE_URL = `https://bo.ulysse.travel`;

export default createStore({
  state: {
    orderId: null,
    order: undefined,
    cancellation: undefined,
    comment: undefined,
    userOptions: {}
  },
  getters: {
    bookings: state => {
      let bookings = [];

      if (state.cancellation) {
        bookings = Object.keys(state.cancellation.bookings)
          .map(BID => {
            const ID = Number(BID);
            const CANCELLATION = state.cancellation.bookings[ID];
            const ORDER = Object.assign(
              state.order.bookings.find(B => B.id == ID),
              {}
            );
            ORDER.segmentsId = ORDER.segments;
            const SEGMENTS = Array.from(ORDER.segmentsId).map(SID =>
              state.order.segments.find(S => S.id === SID)
            );
            const OBJ = {
              ...CANCELLATION,
              ...ORDER,
              segments: SEGMENTS
            };
            return OBJ;
          })
          .sort((a, b) => {
            return (
              new Date(a.segments[0].departing_at) -
              new Date(b.segments[0].departing_at)
            );
          });
      }

      return bookings;
    },
    getBookingOptionsById: state => id => {
      return Object.entries(state.cancellation.bookings[id]).map(
        ([key, value]) => {
          return {
            ...value,
            type: key
          };
        }
      );
    },
    getPrimaryPassenger: state => {
      return state.order ? state.order.passengers[0] : undefined;
    }
  },
  mutations: {
    setOrderId(state, id) {
      state.orderId = id;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setCancellation(state, order) {
      state.cancellation = order;
    },
    setComment(state, order) {
      state.comment = order;
    },
    setUserOption(state, values) {
      const { bookingId, optionType } = values;

      state.userOptions[bookingId] = {
        optionType: optionType,
        ...values
      };
    },
    setUserOptionValue(state, values) {
      const { bookingId, optionValue } = values;

      console.log(values)

      state.userOptions[bookingId].optionValue = optionValue;
    }
  },
  actions: {
    getOrder({ commit, state }) {
      return axios
        .get(`${API_URL}/api/cancellation/getData?id=${state.orderId}`)
        .then(({ data }) => {
          commit("setOrder", data.order);
          commit("setCancellation", data.cancellation);
        })
        .catch(err => {
          console.error(err);
          throw err;
        });
    },
    confirmChoice({ state }) {
      let comment = "";

      for (const key in state.userOptions) {
        const option = state.userOptions[key];
        const optionType = option.optionType;

        comment += `Booking #${key}\n`;
        comment += `Option: ${optionType} \n`;

        if (
          optionType === "date_modification" &&
          option.optionValue.outboundDate !== undefined
        ) {
          comment += `outboundDate: ${option.optionValue.outboundDate} \n`;
        }

        if (
          optionType === "date_modification" &&
          option.optionValue.inboundDate !== undefined
        ) {
          comment += `inboundDate: ${option.optionValue.inboundDate} \n`;
        }

        if (optionType === "cash_refund" && option.price !== undefined) {
          comment += `Amount: ${option.price} \n`;
        }

        if (optionType === "nominative_voucher" && option.price !== undefined) {
          comment += `Amount: ${option.price} \n`;
        }

        comment += `\n\n`;
      }

      comment += `Comment : ${state.comment}`;

      return axios
        .post(`${ULYSSE_URL}/api/cancellation/${state.orderId}/add/`, {
          title: "CSP - PAP selected his choice in CSP",
          comment
        })
        .then(() => {
          return true;
        })
        .catch(err => {
          console.error(err);
          throw err;
        });
    }
  },
  modules: {}
});
