<template>
  <div>
    <div 
      class="p-20 pt-60 select-none rounded border border-dark-20 relative hover:shadow-md transition duration-250 ease-out" 
      :class="`background-${option.type}`"
    >
      <input
        type="radio"
        className="mr-2 absolute top-10 right-10"
        :checked='checked'
        v-if="option.available === true && readOnly === false"
      />

        <div v-if="readOnly === false">
          <p class="text-green-dark mb-0 text-12 font-medium" v-if="option.available === true">Disponible</p>
          <p class="text-danger mb-0 text-12 font-medium" v-else>Non disponible</p>
        </div>

        <div v-if="option.type === 'date_modification'">
          <p className="text-18 mb-10 font-medium">{{optionConfig.title}}</p>
          <p className="text-14 text-dark-80">{{optionConfig.description}}</p>
        </div>
        <div v-else-if="option.type === 'nominative_voucher'">
          <p className="text-18 mb-10 font-medium">{{optionConfig.title}}</p>
          <p className="text-14 text-dark-80" v-if="option.available === true">Recevez un avoir de {{ option.price }} €. Utilisable sur tous les vols de la compagnie jusqu'en {{ $formatDate(option.month_voucher, 'd MMMM yyyy') }}, et strictement lié à votre identité.</p>
          <p className="text-14 text-dark-80" v-else>{{ optionConfig.description }}</p>
        </div>
        <div v-else-if="option.type === 'cash_refund'">
          <p className="text-18 mb-10 font-medium">{{optionConfig.title}}</p>
          <p className="text-14 text-dark-80">{{optionConfig.description}}</p>
        </div>
        <div v-else>
          <p className="text-18 mb-10 font-medium">{{option.title}}</p>
          <p className="text-14 text-dark-80">{{option.description}}</p>
        </div>
        
        <p class="mt-10 text-14 mt-10 mb-10" v-if="option.type === 'date_modification' && option.available === true && readOnly === false">
          Date maximum de validité: <strong>{{ $formatDate(option.month_modification, 'd MMMM yyyy') }}</strong>
        </p>

        <p class="mt-10 text-14 mt-10 mb-10" v-if="option.type === 'cash_refund' && option.available === true">
          Montant du remboursement possible: <strong>{{option.price}}€</strong>
        </p>

        <div class="flex flex-col mt-4" v-if="option.type === 'date_modification' && option.available === true && readOnly === false">
          <label class="text-14" for="date_modification">Choisir ma nouvelle date de vol aller</label>
          <input class="input mt-2" type="date" placeholder="12/01/2025" v-model="value.outboundDate">
          <p class="text-danger text-12 mt-3" v-if="value.outboundDate !== undefined && value.outboundDate > option.month_modification">Date trop loin dans le futur</p>
        </div>

        <div class="flex flex-col mt-4" v-if="option.type === 'date_modification' && option.available === true && readOnly === false && isRoundTrip === true">
          <label class="text-14" for="date_modification">Choisir ma nouvelle date de vol retour</label>
          <input class="input mt-2" type="date" placeholder="12/01/2025" v-model="value.inboundDate">
          <p class="text-danger text-12 mt-3" v-if="value.inboundDate !== undefined && value.inboundDate > option.month_modification">Date trop loin dans le futur</p>
        </div>

        <div class="flex flex-col mt-4" v-if="option.type === 'date_modification' && option.available === true && readOnly === true">
          <p class="text-14 text-strong">Ma nouvelle date de vol aller: <strong>{{ $formatDate(value.outboundDate, 'd MMMM yyyy') }}</strong></p>
        </div>

        <div class="flex flex-col mt-4" v-if="option.type === 'date_modification' && option.available === true && readOnly === true && value.inboundDate !== undefined">
          <p class="text-14 text-strong">Ma nouvelle date de vol retour: <strong>{{ $formatDate(value.inboundDate, 'd MMMM yyyy') }}</strong></p>
        </div>
    </div>
  </div>
</template>

<script>
const OPTIONS = {
  date_modification: {
    title: 'Modification de votre billet',
    description: "Modifiez la date de votre voyage. Tant que vous gardez les mêmes aéroports, la même compagnie, et que c'est avant mars 2021, ça passe !",
    fields: [
      {
        type: "date",
        key: "date_modification",
        title: "Choisir ma nouvelle date de vol",
        placeholder: "12/01/2025",
      },
    ]
  },
  nominative_voucher: {
    title: 'Avoir nominatif',
    description: "Recevez un avoir du montant total de votre voyage à utiliser sur n’importe quel vol de la compagnie."
  },
  cash_refund: {
    title: 'Remboursement',
    description: 'Faites-vous rembourser sur le moyen de paiement utilisé au moment de l’achat du billet.'
  },
  exception: {
    title: 'Options personnalisée',
    description: 'Renseignez une description présentant l’option et ne dépassant pas 350 caractères.'
  },
};

export default {
  name: 'AirlineOption',
  props: {
    option: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isRoundTrip: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      default: () => {
        return  {}
      }
    }
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      outboundDate: undefined,
      inboundDate: undefined
    }
  },
  computed: {
    optionConfig() {
      return OPTIONS[this.option.type];
    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.background-date_modification {
  background-image: url('~@/assets/options/modification.png');
  background-size: 400px;
  background-repeat: no-repeat;
}
.background-cash_refund {
  background-image: url('~@/assets/options/refund.png');
  background-size: 400px;
  background-repeat: no-repeat;
}
.background-nominative_voucher {
  background-image: url('~@/assets/options/nomative_voucher.png');
  background-size: 400px;
  background-repeat: no-repeat;
}

.input {
  @apply rounded border border-dark-20 p-10 text-14;
  
  &::placeholder {
    @apply text-12;
  }
}
</style>
