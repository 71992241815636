<template>
  <main>
    <Navigation />
    <div class="container">
      <div class="row justify-center">
        <div class="lg:col-10 xl:col-8">
          <router-view/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navigation from '@/components/core/Navigation.vue'

export default {
  name: 'Cancellation',
  components: { Navigation },
  mounted() {
    this.$store.commit('setOrderId', this.$route.params.orderId)
    this.$store.dispatch('getOrder')
      .then(() => {
        console.log(this.$store.getters['bookings'])
      })
  },
  computed: {
    order() {
      return this.$store.state.order
    }
  }
}
</script>

<style></style>
