<template>
  <div class="py-50" v-if="order">
    <p class="mb-2 text-24 font-medium mb-20">
      Récapitulatif de votre choix. 
    </p>
    <p class="mb-70">
      Vérifiez avec attention que les informations ci-dessous soient correctes. <br/>
Si vous souhaitez modifier votre choix, c’est le moment ! Une fois validé, vous ne pourrez plus le modifier.
    </p>

    <p class="mb-10">Une remarque en plus à transmettre à notre service client ?</p>
    <textarea class="rounded border border-dark-20 p-10 text-14 w-full" v-model="comment"></textarea>

    <p class="mb-20 mt-30">Voici un petit récapitulatif de votre sélection :</p> 
    <div v-for="booking in bookings" :key="booking.id" class="grid grid-cols-2 gap-30 mb-20">
      <BookingCard
        :key="booking.id"
        :booking="booking"
        :selected="booking.id.toString() === bookingId"
      />

      <AirlineOption :checked="true" :option="selectedOptions[booking.id.toString()]" :read-only="true" :model-value="selectedOptions[booking.id.toString()].optionValue"/>
    </div>


    <Information class="mt-60 mb-30">
      <p class="text-14">
        Notre équipe transmettra votre choix à la compagnie le plus rapidement possible. <br/>
        En revanche, les délais de traitement dépendent ensuite de chaque compagnie en interne. Rassurez-vous, nous suivrons cela de très près ! 
      </p>
    </Information>

    <footer class="flex flex-row bottom-0 justify-end my-20 py-20 bg-white border-t border-dark-20">
      <router-link 
        class="border border-dark-10 text-dark-60 rounded-sm text-black p-10 w-1/2 text-16 text-center mr-5"
        :to="previousStep"
      >
        Retour
      </router-link>

      <router-link 
        class="border-none rounded-sm bg-primary text-white p-10 w-1/2 text-16 text-center ml-5"
        :to="nextStep"
      >
        Confirmer et envoyer
      </router-link>
    </footer>

  </div>
</template>

<script>
import Information from '@/components/Information.vue'
import BookingCard from '@/components/core/BookingCard.vue'
import AirlineOption from '@/components/cancellation/AirlineOption.vue'

export default {
  name: 'SuccessCancellation',
  components: { BookingCard, AirlineOption, Information },
  computed: {
    order() {
      return this.$store.state.order
    },
    bookings() {
      return this.$store.getters['bookings']
    },
    bookingId() {
      return this.$route.params.bookingId;
    },
    pax() {
      return this.$store.state.order.passengers[0]
    },
    selectedOptions() {
      return this.$store.state.userOptions;
    },
    previousStep() {
      return `/cancellation/${this.order.id}/option/${this.bookings[this.bookings.length-1].id}`;
    },
    nextStep() {
      return `/cancellation/${this.order.id}/success`
    },
    comment: {
      get: function() {
        return this.$store.state.comment
      },
      set: function(newValue) {
        this.$store.commit('setComment', newValue)
      }
    }
  },
  mounted() {
    this.$store.dispatch('confirmChoice')
  }
}
</script>
