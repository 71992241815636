<template>
  <div class="py-50 pr-250" v-if="order">
    <p class="text-primary font-medium text-18">
      Merci {{ pax.given_name }},
    </p>
    <p class="mb-20 text-30 font-medium">
      Votre choix a été reçu 5/5 par notre service client !
    </p>
    <p class="mb-20">
      Nous le transmettons à la compagnie aérienne et reviendrons vers vous très prochainement pour la suite des événements.
    </p>

    <p class="mb-50">
      On vous tient au courant de tout. <br/>
      Dès qu’on a une information, vous l’avez aussi ! 
    </p>

    <a 
        class="border block border-dark-10 text-dark-60 rounded-sm text-black p-10 w-full text-16 text-center"
        href="https://aide.ulysse.com/article/116-csp-vol-annule-quelle-est-la-suite?preview=6006e50ecfe30d219ccd92b0"
      >
      En savoir plus sur les prochaines étapes
    </a>

    <a 
      class="mt-20 border-none block rounded-sm bg-primary text-white p-10 w-full text-16 text-center"
      href="https://ulysse.travel/"
    >
      Rechercher un nouveau vol sur Ulysse
    </a>
  </div>

  <img src="@/assets/wad@2x.png" class="absolute right-0 bottom-0 wad"/>
</template>

<script>

export default {
  name: 'SuccessCancellation',
  components: {},
  computed: {
    order() {
      return this.$store.state.order
    },
    bookings() {
      return this.$store.getters['bookings']
    },
    pax() {
      return this.$store.state.order.passengers[0]
    },
    selectedOptions() {
      return this.$store.state.userOptions;
    }
  },
  mounted() {

  }
}
</script>

<style lang="postcss" scoped>
.wad {
  max-width: 700px;
}
</style>
