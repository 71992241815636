<template>
  <div class="border border-dark-20 p-15 rounded">
    <header class="font-medium text-12 mb-5">
      {{ segment.origin_extra.city_data.name }}
      <span class="font-regular">
        vers
      </span>
      {{ segment.destination_extra.city_data.name }}
    </header>
    <div class="flex justify-between">
      <p class="text-12 opacity-80 leading-tight">
        <small class="block text-10 opacity-80">
          Aéroports
        </small>
        {{ segment.origin }} - {{ segment.destination }}
      </p>
      <p class="text-12 opacity-80 leading-tight mx-10">
        <small class="block text-10 opacity-80">
          Date
        </small>
        {{ $formatDate(segment.departing_at, 'PP') }}
      </p>
      <p
        class="text-12 leading-tight text-right"
        :class="{
          'text-success': segment.status === 'confirmed',
          'text-danger': segment.status === 'cancelled'
        }"
      >
        <small class="block text-dark text-10 opacity-60">
          Statut
        </small>
        {{ segment.status === 'confirmed' ? 'Maintenu' : 'Annulé' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SegmentMini',
  props: ['segment']
}
</script>
