<template>
  <section>
    <p class="text-16 font-medium leading-none mb-10">
      Informations
    </p>
    <div class="flex rounded overflow-hidden border border-blue bg-blue-background">
      <img src="@/assets/information.png" alt="Information" class="w-150" style="max-height: 128px;"/>
      <main class="pr-30 pt-30 pb-30">
        <slot />
      </main>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Information'
}
</script>
