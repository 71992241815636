/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import Cancellation from '@/views/cancellation/Cancellation.vue'
import HomeCancellation from "@/views/cancellation/Home.vue";
import SuccessCancellation from "@/views/cancellation/Success.vue";
import BookingCancellation from "@/views/cancellation/Booking.vue";
import RecapCancellation from "@/views/cancellation/Recap.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/cancellation",
    redirect: {
      name: "HomeCancellation"
    }
  },
  {
    path: "/cancellation/:orderId",
    name: "Cancellation",
    component: Cancellation,
    children: [
      {
        path: "home",
        name: "HomeCancellation",
        component: HomeCancellation
      },
      {
        path: "success",
        name: "SuccessCancellation",
        component: SuccessCancellation
      },
      {
        path: "recap",
        name: "RecapCancellation",
        component: RecapCancellation
      },
      {
        path: "option/:bookingId",
        component: BookingCancellation
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    return { left: 0, top: 0 };
  }
});

export default router
